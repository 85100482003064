import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import store from './state/store'
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css'; 

ReactDOM.render(<Provider store={store}>
    <App />
    </Provider>, document.getElementById('root'));
